<template>
  <div class="profil-container">
    <div class="profil-block">
        <div class="first-block">
            <div class="user-data"></div>
            <div class="user-data2"></div>
        </div>
        <div class="second-block">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <div class="third-block"></div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
*{
    box-sizing: border-box;
}
.profil-container{
    padding: 0 15%;
    display: block;
    width: 100%;
    height: auto;
    min-height: 100vh;
}
.profil-block{
    display: block;
    width: 100%;
    height: auto;
    background: white;
}
.profil-block .first-block{
    display: grid;
    grid-template-columns: 1fr 3fr;
    column-gap: 10px;
    width: 100%;
    height: 300px;
}
.user-data{
    grid-area: 1/1/ 2 / 2;
    background: #eee;
    border-radius: 0 0 16px 16px;
}
.user-data2{
    grid-area: 1/2/ 2 / 3;
    background: #eee;
    border-radius: 0 0 16px 16px;
}
.second-block{
    padding: 10px 0;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    column-gap: 10px;
    height: calc(20px + 15rem);
    width: 100%;
}
.second-block div{
    border-radius: 16px;
    width: 100%;
    height: 100%;
    background: #eee;
}
.third-block{
    width: 100%;
    height: 600px;
    background: #eee;
    border-radius: 16px 16px 0 0;
}
</style>